<template>
  <div class="about">
    <div class="brief-content">
      <div class="describe">
        撒打发斯蒂芬

      </div>

    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
@import "./../../assets/scss/base.scss";

.about {
  .brief-content {
    .describe {

      p {
        line-height: 30px;
        font-size: 16px;
        color: #414141;
        padding: 0 5px 30px;
      }
    }
    .img {
      margin: 40px auto 200px;
    }
  }
}
</style>
